import { Close } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { CSSProperties, FunctionComponent, useState } from 'react';

import useBreakpoints from '../Hooks/useBreakpoints';
import Line from '../Line';

const style: CSSProperties = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '600px',
  height: '600px',
  backgroundColor: 'var(--default-white)',
  padding: '48px 32px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  scrollbarWidth: 'thin',
};

const span: CSSProperties = {
  cursor: 'pointer',
};

const div: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignContent: 'center',
  alignItems: 'center',
};

// eslint-disable-next-line require-jsdoc
const BasicModal: FunctionComponent<{
  title: string;
  label?: string;
  children: any;
}> = ({ title, label, children }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { isDesktop } = useBreakpoints();
  style.width = isDesktop ? '600px' : '100vw';
  return (
    <>
      <span onClick={handleOpen} style={span}>
        <u>{label || title}</u>
      </span>
      <Modal keepMounted open={open} onClose={handleClose}>
        <Box sx={style}>
          <div style={div} className="barlow--size24 bold">
            <div>{title}</div>
            <div style={span} onClick={handleClose}>
              <Close htmlColor="var(--primary-blue)" />
            </div>
          </div>
          <Line />
          <span className="open-sans--size16" style={{ overflowY: 'auto' }}>
            {children}
          </span>
        </Box>
      </Modal>
    </>
  );
};

export default BasicModal;
